// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-author-jsx": () => import("./../../../src/templates/Author.jsx" /* webpackChunkName: "component---src-templates-author-jsx" */),
  "component---src-templates-main-jsx": () => import("./../../../src/templates/Main.jsx" /* webpackChunkName: "component---src-templates-main-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/Post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

