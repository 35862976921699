module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","theme_color":"#663399","background_color":"#663399","icon":"src/images/favicon.svg","display":"minimal-ui","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"501803e18b43218f8543bcc94ddd57b7"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":false,"wrapperStyle":"margin-bottom: 5rem; white-space: initial;"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PBTBN4K","includeInDevelopment":false,"gtmAuth":"","gtmPreview":""},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
